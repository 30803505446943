.App {
  text-align: center;
}

/* For the Scroll Bar */

::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-thumb {
  background: linear-gradient(#272f32, #41acda);
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(#41acda, #272f32);
}

/* For the Navbar */

.navbar-nav .nav-link:hover,
.navbar-nav .nav-link:focus,
.navbar-nav .nav-link-active {
  color: #41acda !important;
}

.nav-link {
  padding: 0 20px; /* Add padding to each side of the link */
  font-size: 1.0rem; /* Increase the font size */
}

.nav-link::after {
  content: "";
  display: block;
  height: 2px;
  width: 0; /* Start with zero width */
  background-color: #41acda;
  transform: scaleX(0); /* Use scaleX to animate from left to right */
  transform-origin: left; /* Set the transform origin to the left */
  transition: transform 0.3s ease, width 0.3s ease; /* Add transition for transform and width */
  transition-delay: 0.1s; /* Add a delay of 0.5 seconds */
}

.nav-link:hover::after,
.nav-link:focus::after,
.nav-link-active::after {
  width: 100%; /* When hovering, expand the width to 100% */
  transform: scaleX(1); /* Scale the underline from left to right */
}

.navbar-nav .btn-login:hover {
  background-color: #41acda !important;
  color: #272f32 !important;
}

.navbar-nav .btn-editor:hover {
  background-color: transparent !important;
  border: 1px solid #41acda !important;
  color: white !important;
}

.hover-color-transition {
  transition: color 0.3s ease;
}

.hover-color-transition:hover {
  color: #41acda;
}

.icon-color{
  color: #41acda;
}

.social-icon{
  font-size: 22px;
  margin: 5px;
}

/* Animations */

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateY(20%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideUp {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}

.transform {
  transform: translateY(0);
}

.translate-y-10 {
  transform: translateY(10%);
}

.translate-y-0 {
  transform: translateY(0);
}

.transition-all {
  transition: all 1s ease;
}

.duration-1000 {
  transition-duration: 1s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fadeIn {
  animation: fadeIn 1s ease-in-out;
}

